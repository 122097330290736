import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Header from "./components/Header";
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import ServicesPage from "./pages/ServicesPage";
import ContactPage from "./pages/ContactPage";
import OrderPage from "./pages/OrderPage";

const App = () => {
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (!hasRunRef.current) {
      window.main();
      hasRunRef.current = true;
    }
  }, []);

  return (
    <>
      <Router>
        <TopBar />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/order" element={<OrderPage />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
};

export default App;
