import React, { useState, useEffect } from "react";
import Breadcrumbs from "../components/Breadcrumbs";

const OrderPage = () => {
  const [service, setService] = useState("");
  const [fromLanguage, setFromLanguage] = useState("");
  const [toLanguage, setToLanguage] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [comments, setComments] = useState("");
  const [price, setPrice] = useState(0);
  const [vat, setVat] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    calculateTotalPrice();
  }, [price, vat, discount]);

  const handleServiceChange = (e) => {
    setService(e.target.value);
    calculatePrice(e.target.value, fromLanguage, toLanguage, pageCount);
  };

  const handleLanguageChange = (e, type) => {
    if (type === "from") {
      setFromLanguage(e.target.value);
    } else {
      setToLanguage(e.target.value);
    }
    calculatePrice(service, fromLanguage, toLanguage, pageCount);
  };

  const handlePageCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setPageCount(count);
    calculatePrice(service, fromLanguage, toLanguage, count);
  };

  const calculatePrice = (service, fromLanguage, toLanguage, count) => {
    let pricePerPage = 0;
    if (service === "translation") {
      if (fromLanguage === "english" || toLanguage === "english") {
        pricePerPage = 12;
      } else {
        pricePerPage = 15;
      }
    } else if (service === "editing") {
      pricePerPage = 7;
    }
    const basePrice = pricePerPage * count;
    setPrice(basePrice);
    setVat(basePrice * 0.2); // 20% VAT
    setDiscount(basePrice * 0.1); // 10% discount for example
  };

  const calculateTotalPrice = () => {
    const total = price + vat - discount;
    setTotalPrice(total);
  };

  return (
    <>
      <Breadcrumbs />
      <section id="order" className="order" style={sectionStyle}>
        <div className="container">
          <div className="section-title">
            <h2>Поръчайте Услуга</h2>
            <p>Моля, попълнете формата по-долу, за да направите поръчка.</p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <form>
                <div className="form-group" style={formGroupStyle}>
                  <label htmlFor="service">Избор на Услуга</label>
                  <select
                    id="service"
                    className="form-control"
                    value={service}
                    onChange={handleServiceChange}
                  >
                    <option value="">-- Изберете услуга --</option>
                    <option value="translation">Превод</option>
                    <option value="editing">Редакция</option>
                  </select>
                </div>
                {service === "translation" && (
                  <>
                    <div className="form-group" style={formGroupStyle}>
                      <label htmlFor="fromLanguage">От Какъв Език</label>
                      <select
                        id="fromLanguage"
                        className="form-control"
                        value={fromLanguage}
                        onChange={(e) => handleLanguageChange(e, "from")}
                      >
                        <option value="">-- Изберете език --</option>
                        <option value="english">Английски</option>
                        <option value="bulgarian">Български</option>
                        <option value="french">Френски</option>
                        <option value="german">Немски</option>
                        {/* Добавете още езици при нужда */}
                      </select>
                    </div>
                    <div className="form-group" style={formGroupStyle}>
                      <label htmlFor="toLanguage">На Какъв Език</label>
                      <select
                        id="toLanguage"
                        className="form-control"
                        value={toLanguage}
                        onChange={(e) => handleLanguageChange(e, "to")}
                      >
                        <option value="">-- Изберете език --</option>
                        <option value="english">Английски</option>
                        <option value="bulgarian">Български</option>
                        <option value="french">Френски</option>
                        <option value="german">Немски</option>
                        {/* Добавете още езици при нужда */}
                      </select>
                    </div>
                  </>
                )}
                <div className="form-group" style={formGroupStyle}>
                  <label htmlFor="pageCount">Брой Страници</label>
                  <input
                    type="number"
                    id="pageCount"
                    className="form-control"
                    value={pageCount}
                    onChange={handlePageCountChange}
                    min="1"
                  />
                </div>
                <div className="form-group" style={formGroupStyle}>
                  <label htmlFor="comments">Коментари и Друго</label>
                  <textarea
                    id="comments"
                    className="form-control"
                    rows="5"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-danger" style={buttonStyle}>
                    Поръчай
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-6">
              <div className="calculator" style={calculatorStyle}>
                <h3>Калкулатор на Цената</h3>
                <p>Базова Цена: {price} лв.</p>
                <p>ДДС (20%): {vat.toFixed(2)} лв.</p>
                <p>Отстъпка (10%): {discount.toFixed(2)} лв.</p>
                <h4>Обща Цена: {totalPrice.toFixed(2)} лв.</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const sectionStyle = {
  padding: "40px 0",
};

const formGroupStyle = {
  marginBottom: "20px"
};

const buttonStyle = {
  backgroundColor: "#ff5821",
  border: "none",
  color: "#fff",
  padding: "10px 24px",
  borderRadius: "4px",
  cursor: "pointer"
};

const calculatorStyle = {
  padding: "20px",
  backgroundColor: "#fff",
  boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px"
};

export default OrderPage;
