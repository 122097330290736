import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import AboutText from "../components/AboutText";
import ServicesSection from "../components/ServicesSection";

const AboutPage = () => {
  return (
    <>
      <Breadcrumbs />
      <AboutText />
      <ServicesSection />
    </>
  );
};

export default AboutPage;
