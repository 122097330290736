import React from "react";
import HeroSection from "../components/HeroSection";
import FeaturedSection from "../components/FeaturedSection";
import AboutSection from "../components/AboutSection";
import ClientsSection from "../components/ClientsSection";
import ServicesSection from "../components/ServicesSection";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <FeaturedSection />
      <AboutSection />
      <ServicesSection />
      <ClientsSection />
    </>
  );
};

export default HomePage;
