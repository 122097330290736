import React from "react";

const AboutText = () => {
  const containerStyle = {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "10px"
  };

  const sectionTitleStyle = {
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: "30px"
  };

  const sectionSubtitleStyle = {
    textAlign: "center",
    color: "#777",
    marginBottom: "50px"
  };

  const rowStyle = {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px"
  };

  const colStyle = {
    flex: "1",
    padding: "10px",
    minWidth: "300px"
  };

  const imgStyle = {
    width: "100%",
    borderRadius: "8px"
  };

  const iconListStyle = {
    listStyleType: "none",
    padding: "0",
    textAlign: "left"
  };

  const listItemStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px"
  };

  const iconStyle = {
    color: "green",
    marginRight: "10px"
  };

  return (
    <section id="about">
      <div style={containerStyle}>
        <div style={sectionTitleStyle}>
          <h2>За Нас</h2>
        </div>
        <p style={sectionSubtitleStyle}>Научете повече за нашата история, преводачи и подход</p>
        <div style={rowStyle}>
          <div style={colStyle}>
            <p>
              Агенция за преводи ProTRANS е водеща фирма в областта на
              професионалните преводи и лингвистични услуги. С над 6 години опит в
              индустрията и богат опит в областта на преводите, ние сме надежден и
              трудолюбив партньор за всички вашите лингвистични нужди.
            </p>
            <p>
              Нашата история и съпътстващият опит съставляват основата на нашата
              ангажираност и професионализъм. През годините сме изградили
              дълготрайни връзки с клиенти от различни индустрии и региони на света.
              Този богат партньорски опит ни е помогнал да разберем специфичните
              изисквания на всеки клиент и да се адаптираме към техните уникални
              предизвикателства.
            </p>
            <p>
              Процесът на превод е изкуство, което изисква понимание на как да се
              предаде смисълът, тонът и контекстът на оригиналния текст. Нашите
              преводачи са избрани с голяма внимателност и специализирани в различни
              области, което гарантира, че вашите преводи са точни и вярни на
              целевия език и култура.
            </p>
          </div>
          <div style={colStyle}>
            <img src="assets/img/about.jpg" style={imgStyle} alt="About Us" />
          </div>
        </div>
        <div style={rowStyle}>
          <div style={colStyle}>
            <h3>Нашата история</h3>
            <p>
              ProTRANS бе основана през 2017 година с цел предоставяне на
              висококачествени преводачески услуги на клиенти от цял свят. С течение
              на годините ние развихме силни връзки с клиенти и преводачи, което ни
              позволява да предлагаме изключително качество на нашите услуги.
            </p>
          </div>
          <div style={colStyle}>
            <h3>Нашите преводачи</h3>
            <p>
              Основният актив на ProTRANS са нашите преводачи. Разполагаме с екип от
              над 20 квалифицирани и опитни преводачи, всеки от които е специалист в
              своята област. Те са експерти в различни сфери като право, медицина,
              информационни технологии, маркетинг и други. Нашите преводачи са
              преданите на професионализма и точността и винаги се стремят да
              осигурят най-доброто в преводите си.
            </p>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={colStyle}>
            <h3>Нашият езиков спектър</h3>
            <p>
              ProTRANS предоставя услуги на клиенти от различни части на света и с
              разнообразни езикови изисквания. Ние се гордеем, че работим с над 30
              различни езика, включително, но не само, английски, испански, френски,
              германски, италиански, руски, китайски и японски. Нашата широка гама
              от езици ни позволява да се адаптираме към специфичните нужди на всеки
              клиент и да предоставяме преводи, които отговарят на най-високите
              стандарти на качество.
            </p>
          </div>
          <div style={colStyle}>
            <h3>Нашият подход</h3>
            <p>
              В ProTRANS ние вярваме в индивидуалния подход към всеки клиент и
              проект. Нашата цел е да разберем вашите нужди и да предоставим превод,
              който не само отразява точно смисъла на текста, но и запазва стиловете
              и нюансите му. Ние се стремим към преводи, които са не само точни, но
              и приятни за четене и усвояване от целевата аудитория.
            </p>
            <p>
              С ProTRANS вие получавате повече от просто превод. Вие получавате
              лингвистичен партньор, който ви помага да комуникирате с глобалната
              аудитория. Ние сме тук, за да ви помогнем да преодолеете езиковите
              бариери и да постигнете успех в международната арена. Свържете се с
              нас днес и нека работим заедно, за да реализираме вашите лингвистични
              цели и да ви помогнем да достигнете нови върхове в бизнеса и
              комуникацията. В ProTRANS вярваме, че качеството на превода е ключът
              към успешните международни отношения.
            </p>
          </div>
        </div>
        <div style={sectionTitleStyle}>
          <h3>Защо да ни се доверите?</h3>
        </div>
        <ul style={iconListStyle}>
          <li style={listItemStyle}>
            <i className="bi bi-check-circle" style={iconStyle}></i> Професионални Преводачи:
            Нашият екип от опитни преводачи владеят над 20 езика и се грижат
            за всяко ваше запитване.
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-check-circle" style={iconStyle}></i> Гарантирано Качество: При
            нас качеството е приоритет. Всеки текст е предмет на стриктен
            редакторски контрол.
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-check-circle" style={iconStyle}></i> Бърза Обработка: Вашите
            преводи ще бъдат изготвени бързо и ефективно, без излишни
            забавяния.
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-check-circle" style={iconStyle}></i> Сигурност на
            Информацията: Вашият текст и данни са защитени с най-съвременни
            технологии за сигурност.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default AboutText;
