import React from "react";

const ContactForm = () => {
  return (
    <>
        <section id="contact" className="contact" style={{ padding: "60px 0" }}>
            <div className="container">
            <div className="row">
                <div className="col-lg-6">
                <div className="info-box mb-4" style={infoBoxStyle}>
                    <i className="bx bx-map" style={iconStyle}></i>
                    <h3>Нашият Адрес</h3>
                    <p>A108 Adam Street, New York, NY 535022</p>
                </div>
                </div>
                <div className="col-lg-3 col-md-6">
                <div className="info-box mb-4" style={infoBoxStyle}>
                    <i className="bx bx-envelope" style={iconStyle}></i>
                    <h3>Имейл</h3>
                    <p>contact@example.com</p>
                </div>
                </div>
                <div className="col-lg-3 col-md-6">
                <div className="info-box mb-4" style={infoBoxStyle}>
                    <i className="bx bx-phone-call" style={iconStyle}></i>
                    <h3>Телефон</h3>
                    <p>+1 5589 55488 55</p>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                <iframe
                    className="mb-4 mb-lg-0"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                    style={{ border: 0, width: "100%", height: "384px" }}
                    allowFullScreen
                ></iframe>
                </div>
                <div className="col-lg-6">
                <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                    <div className="row">
                    <div className="col-md-6 form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Вашето име" required />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Вашият имейл" required />
                    </div>
                    </div>
                    <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Относно" required />
                    </div>
                    <div className="form-group mt-3">
                    <textarea className="form-control" name="message" rows="5" placeholder="Съобщение" required></textarea>
                    </div>
                    <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Вашето съобщение е изпратено. Благодаря ви!</div>
                    </div>
                    <div className="text-center">
                    <button type="submit" style={buttonStyle}>Изпрати съобщение</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </section>
    </>
  );
};

const infoBoxStyle = {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)"
};
  
const iconStyle = {
    fontSize: "24px",
    color: "#ff5821",
    marginBottom: "10px"
};

const buttonStyle = {
    border: "none",
    color: "#fff",
    padding: "10px 24px",
    borderRadius: "4px",
    cursor: "pointer"
};

export default ContactForm;
