import React from "react";

const HeroSection = () => {
  return (
    <section id="hero">
      <div className="hero-container">
        <div
          id="heroCarousel"
          data-bs-interval="5000"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <ol
            className="carousel-indicators"
            id="hero-carousel-indicators"
          ></ol>
          <div className="carousel-inner" role="listbox">
            <div
              className="carousel-item active"
              style={{ backgroundImage: "url(assets/img/slide/slide-1.jpg)" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2 className="animate__animated animate__fadeInDown">
                    <span>Преводи</span> от и на <span>20+</span> езика
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Ние осигуряваме професионални преводи от и на повече от 20
                    езика, гарантирайки ви ефективна комуникация в глобален
                    мащаб.
                  </p>
                  <a
                    href=""
                    className="btn-get-started animate__animated animate__fadeInUp"
                  >
                    Поръчай онлайн
                  </a>
                </div>
              </div>
            </div>
            <div
              className="carousel-item"
              style={{ backgroundImage: "url(assets/img/slide/slide-2.jpg)" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2 className="animate__animated animate__fadeInDown">
                    Редакция на текст на <span>30+</span> езика
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Внимателно и професионално преглеждане на текстовете, за да
                    гарантираме тяхната ясност, граматична точност и стилова
                    перфекция.
                  </p>
                  <a
                    href=""
                    className="btn-get-started animate__animated animate__fadeInUp"
                  >
                    Поръчай онлайн
                  </a>
                </div>
              </div>
            </div>
            <div
              className="carousel-item"
              style={{ backgroundImage: "url(assets/img/slide/slide-3.jpg)" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2 className="animate__animated animate__fadeInDown">
                    <span>Гарантирано качество</span> на услугите
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Нашате услуги се отличават с гарантирано качество, което ви
                    осигурява надеждност и увереност в резултатите. 98% от
                    нашите клиенти ни се доверят повече от веднъж.
                  </p>
                  <a
                    href=""
                    className="btn-get-started animate__animated animate__fadeInUp"
                  >
                    Нашите услуги
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#heroCarousel"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bi bi-chevron-left"
              aria-hidden="true"
            ></span>
          </a>
          <a
            className="carousel-control-next"
            href="#heroCarousel"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bi bi-chevron-right"
              aria-hidden="true"
            ></span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
