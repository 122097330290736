import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import ServicesSection from "../components/ServicesSection";

const ServicesPage = () => {
  return (
    <>
      <Breadcrumbs />
      <ServicesSection />
    </>
  );
};

export default ServicesPage;
