import React from "react";

const AboutSection = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src="assets/img/about.jpg" className="img-fluid" alt="" />
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 content">
            <div className="section-title" style={{ paddingBottom: "10px" }}>
              <h2>Защо да ни се доверите?</h2>
              <p style={{ textAlign: "left" }}>
                В света на бързите комуникации, нашата услуга за онлайн преводи
                премахва езиковите бариери и свързва хора от целия свят -
                бизнеспартньори, студенти, пътешественици и всички, които търсят
                надеждни и точни преводи на текстове, документи и съобщения.
              </p>
            </div>
            <ul>
              <li>
                <i className="bi bi-check-circle"></i> Професионални Преводачи:
                Нашият екип от опитни преводачи владеят над 20 езика и се грижат
                за всяко ваше запитване.
              </li>
              <li>
                <i className="bi bi-check-circle"></i> Гарантирано Качество: При
                нас качеството е приоритет. Всеки текст е предмет на стриктен
                редакторски контрол.
              </li>
              <li>
                <i className="bi bi-check-circle"></i> Бърза Обработка: Вашите
                преводи ще бъдат изготвени бързо и ефективно, без излишни
                забавяния.
              </li>
              <li>
                <i className="bi bi-check-circle"></i> Сигурност на
                Информацията: Вашият текст и данни са защитени с най-съвременни
                технологии за сигурност.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
