import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [activeLink, setActiveLink] = useState("/");
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <header id="header" className="d-flex align-items-center">
      <div
        className="container d-flex justify-content-between align-items-center"
        style={{ padding: "10px 0" }}
      >
        <div className="logo">
          <h1>
            <a href="/">ProTRANS</a>
          </h1>
        </div>
        <nav id="navbar" className="navbar">
          <ul style={{ listStyle: "none", display: "flex", padding: 0 }}>
            <li>
              <a
                className={activeLink === "/" ? "active" : ""}
                href="/"
                style={{
                  textDecoration: "none",
                  padding: "10px 20px",
                  color: activeLink === "/" ? "red" : "black",
                }}
              >
                Начало
              </a>
            </li>
            <li>
              <a
                className={activeLink === "/about" ? "active" : ""}
                href="/about"
                style={{
                  textDecoration: "none",
                  padding: "10px 20px",
                  color: activeLink === "/about" ? "red" : "black",
                }}
              >
                За нас
              </a>
            </li>
            <li>
              <a
                className={activeLink === "/services" ? "active" : ""}
                href="/services"
                style={{
                  textDecoration: "none",
                  padding: "10px 20px",
                  color: activeLink === "/services" ? "red" : "black",
                }}
              >
                Услуги
              </a>
            </li>
            <li>
              <a
                className={activeLink === "/contact" ? "active" : ""}
                href="/contact"
                style={{
                  textDecoration: "none",
                  padding: "10px 20px",
                  color: activeLink === "/contact" ? "red" : "black",
                }}
              >
                Контакти
              </a>
            </li>
          </ul>
          <button className="btn btn-danger" style={{ marginLeft: "30px" }}>
            <a
              style={{
                padding: "10px 20px",
                color: "white",
                textDecoration: "none",
              }}
              href="/order"
            >
              Поръчай онлайн
            </a>
          </button>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
