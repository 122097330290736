import React from "react";

const ServicesSection = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="section-title">
          <h2>Нашите Услуги</h2>
          <p>
            С новата ни онлайн платформа можете лесно да правите онлайн поръчки
            за преводи и редакция на текстове бързо, удобно и надеждно.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-card-checklist"></i>
              </div>
              <h4>
                <a href="">Преводи от и на 20+ езика</a>
              </h4>
              <p>
                Ние осигуряваме професионални преводи от и на повече от 20
                езика, гарантирайки ви ефективна комуникация в глобален мащаб.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-bar-chart"></i>
              </div>
              <h4>
                <a href="">Редакция на текст</a>
              </h4>
              <p>
                Внимателно и професионално преглеждане на текстовете, за да
                гарантираме тяхната ясност, граматична точност и стилова
                перфекция.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-shield-check"></i>
              </div>
              <h4>
                <a href="">Гарантирано качество</a>
              </h4>
              <p>
                Нашите услуги се отличават с гарантирано качество, което ви
                осигурява надеждност и увереност в резултатите.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-globe"></i>
              </div>
              <h4>
                <a href="">Локализация на софтуер</a>
              </h4>
              <p>
                Превод и адаптация на софтуерни продукти за различни пазари и култури.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-translate"></i>
              </div>
              <h4>
                <a href="">Устни преводи</a>
              </h4>
              <p>
                Професионални устни преводи за бизнес срещи, конференции и други събития.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-pencil-square"></i>
              </div>
              <h4>
                <a href="">Копирайтинг услуги</a>
              </h4>
              <p>
                Създаване на оригинално съдържание, което е убедително и добре написано.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-file-earmark-text"></i>
              </div>
              <h4>
                <a href="">Редакция и корекция на текстове</a>
              </h4>
              <p>
                Подобряване на вашите текстове чрез професионална редакция и корекция.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-journal"></i>
              </div>
              <h4>
                <a href="">Превод на документи</a>
              </h4>
              <p>
                Превод на официални документи, договори, сертификати и други важни документи.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon">
                <i className="bi bi-megaphone"></i>
              </div>
              <h4>
                <a href="">Маркетингови преводи</a>
              </h4>
              <p>
                Превод и адаптация на маркетингови материали за международни кампании.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
